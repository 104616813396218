/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from "react"
import QuickTable from "../../components/QuickTable";
import PCATField from "../PCAT/PCATField";
import { Button, Modal } from "react-bootstrap";
import { usdFormatter } from "../../tools";

export default function BudgetExpenses({budgetExpenses, originalBudgetExpenses, handleSetBudget}) {
    const [showModal, setShowModal] = useState(false);

    const handleSetBudgetExpenses = (key, value) => {
        const newBudgetExpenses = budgetExpenses.duplicate();
        newBudgetExpenses[key] = value;
        handleSetBudget('budgetExpenses', newBudgetExpenses);
    }

    const rows = [
	    {
            key: 'driverWageLight',
            label: 'Driver Daily Wage (Light)',
            type: 'Daily',
            onBlur: 'usd',
            max: 9999.99,
        },
	    {
            key: 'driverWageStandard',
            label: 'Driver Daily Wage (Standard)',
            type: 'Daily',
            onBlur: 'usd',
            max: 9999.99,
        },
	    {
            key: 'driverWageStraight',
            label: 'Driver Daily Wage (Straight)',
            type: 'Daily',
            onBlur: 'usd',
            max: 9999.99,
        },
	    {
            key: 'aoWages',
            label: 'AO Wages',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'bcWages',
            label: 'BC Wages',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'adminWages',
            label: 'Admin Wages',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'uniforms',
            label: 'Uniforms',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'payrollTaxes',
            label: 'Payroll Taxes',
            type: '%',
            onBlur: 'percent',
            max: 999.999,
        },
	    {
            key: 'driverWorkersComp',
            label: 'Driver\'s Workers Comp',
            type: '%',
            onBlur: 'percent',
            max: 999.999,
        },
	    {
            key: 'bcWorkersComp',
            label: 'AO/BC/Admin Workers Comp',
            type: '%',
            onBlur: 'percent',
            max: 999.999,
        },
	    {
            key: 'healthInsurance',
            label: 'Health Insurance',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'lifeInsurance',
            label: 'Life Insurance & Short Term Disability',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'dentalAndVision',
            label: 'Dental & Vision',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'pensionAndProfitSharing',
            label: 'Pension/Profit Sharing',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'drugScreens',
            label: 'Drug Screens',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'four01KMatch',
            label: '401K Match',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'four01KAdmin',
            label: '401K Administration',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'dotPhysicals',
            label: 'DOT Physicals',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'adminFee',
            label: 'Admin Fee',
            type: '%',
            onBlur: 'percent',
            max: 999.999,
        },
	    {
            key: 'recruiting',
            label: 'Recruiting',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'fuel',
            label: 'Fuel',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'maintenance',
            label: 'Maintenance',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'truckPayments',
            label: 'Truck Payments',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'licenseAndRegistration',
            label: 'Licenses/Registrations',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'insurance',
            label: 'Insurance',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'vanWashing',
            label: 'Truck Washing',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'vanDecals',
            label: 'Truck Decals',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'truckRentals',
            label: 'Truck Rentals',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'technology',
            label: 'Technology (Scanners/Tracking)',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'other',
            label: 'Misc. Costs/Other Expenses',
            type: 'Monthly',
            onBlur: 'usd',
            button: true,
        }
    ].map((row) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                <td>{row.type}</td>
                <td>
                    {!row.button ?
                        <PCATField 
                            fontSize={15}
                            max={row.max}
                            value={budgetExpenses[row.key]} 
                            originalValue={originalBudgetExpenses ? originalBudgetExpenses[row.key] : undefined}
                            setValue={(value) => {handleSetBudgetExpenses(row.key, value)}} 
                            usdOnBlur={row.onBlur === 'usd'} 
                            percentOnBlur={row.onBlur === 'percent'}
                        />
                    :
                    <Button onClick={()=>setShowModal(true)} variant="outline-primary" style={{padding: '0px 6px 0px 6px', fontSize: 15, minWidth: '100%', minHeight: '100%', textAlign: 'left'}}>{usdFormatter.format(budgetExpenses.combineOtherExpenses().toNumber())}</Button>
                    }
                </td>
            </tr>
        )
    })

    const miscGaRows = [
	    {
            key: 'bankCharges',
            label: 'Bank Charges',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'officeSupplies',
            label: 'Offices Supplies',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'postage',
            label: 'Postage',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'computer',
            label: 'Computer',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'professional',
            label: 'Professional',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'internet',
            label: 'Internet',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'officeRent',
            label: 'Office Rent',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'officeUtilities',
            label: 'Offices Utilities',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'officeEquipmentLease',
            label: 'Office Equipment Lease',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'cellPhone',
            label: 'Cell Phone',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'ePLI',
            label: 'EPLI',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'otherGA',
            label: 'Other G&A',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
    ].map((row) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                <td>{row.type}</td>
                <td>
                     <PCATField 
                        fontSize={15}
                        max={row.max}
                        value={budgetExpenses[row.key]} 
                        originalValue={originalBudgetExpenses ? originalBudgetExpenses[row.key] : undefined}
                        setValue={(value) => {handleSetBudgetExpenses(row.key, value)}} 
                        usdOnBlur={row.onBlur === 'usd'} 
                        percentOnBlur={row.onBlur === 'percent'}
                    />
                </td>
            </tr>
        )
    })

    const miscOtherRows = [
	    {
            key: 'tolls',
            label: 'Tolls',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'drivingTraining',
            label: 'Driving Training',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'accidents',
            label: 'Accidents',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'medical15K',
            label: 'Medical 15K - Program',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'employeeMeals',
            label: 'Employee Meals',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'shopSupplies',
            label: 'Shop Supplies',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
	    {
            key: 'other',
            label: 'Other',
            type: 'Monthly',
            onBlur: 'usd',
            max: 99999999.99,
        },
    ].map((row) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                <td>{row.type}</td>
                <td>
                     <PCATField 
                        fontSize={15}
                        max={row.max}
                        value={budgetExpenses[row.key]} 
                        originalValue={originalBudgetExpenses ? originalBudgetExpenses[row.key] : undefined}
                        setValue={(value) => {handleSetBudgetExpenses(row.key, value)}} 
                        usdOnBlur={row.onBlur === 'usd'} 
                        percentOnBlur={row.onBlur === 'percent'}
                    />
                </td>
            </tr>
        )
    })

    return (
        <>
        <QuickTable fontSize={15} title='Expenses' responsive={false} headers={['Expense', 'Frequency', 'Amount']} rows={rows} size='sm'/>
        <Modal show={showModal} onHide={()=>setShowModal(false)}  size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Misc. Expenses</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <QuickTable fontSize={15} title='General & Administrative Expenses' headers={['Expense', 'Frequency', 'Amount']} responsive={false} rows={miscGaRows} size='sm'/>
                    <QuickTable fontSize={15} title='Other Operating Expenses' headers={['Expense', 'Frequency', 'Amount']} responsive={false} rows={miscOtherRows} size='sm'/>
                </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" style={{width: '100%'}} onClick={()=>{ setShowModal(false)}}>Close</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}